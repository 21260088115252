<template>
  <a-col
    :id="'trace-page-video-single' + index"
    :xs="24"
    :sm="24"
    :md="24"
    :lg="12"
    :xl="12"
  >
    <a-card
      size="small"
      hoverable
      style="cursor: auto"
      :body-style="{ display: 'flex' }"
    >
      <!-- <a-row> -->
      <div class="pt-2">
        <img
          v-if="video.isThumbnailExist"
          :id="'trace-page-video-' + index + '-thumbnail'"
          class="clickable video-img"
          :src="thumbnailImage"
          :alt="getFileName(video.fileName)"
          @click="handleVideoPlay"
          @error="handleError(video)"
        />

        <NotFoundImage v-else :alt-url="thumbnailImage" />
      </div>
      <div class="d-flex flex-column pl-3">
        <a-descriptions class="text-start">
          <a-descriptions-item
            label="Cycle Identifier"
            span="3"
            :content-style="{
              display: 'flex',
              alignItems: 'center',
              width: '100% !important',
            }"
            :label-style="{
              display: 'flex',
              alignItems: 'center',
            }"
          >
            <!-- <div class="w-100"> -->
            <a-tag color="blue">
              {{ video?.cycle_identifier }}
            </a-tag>
            <a-tag :color="getCycleTagColor">
              {{ getCycleTagText }}
            </a-tag>
            <a-space class="ml-auto" size="middle">
              <a-tooltip title="Bookmark this video">
                <span>
                  <a-rate
                    :value="Number(video.starred)"
                    style="margin-top: -0.3em; font-size: 1.8em"
                    :count="1"
                    @change="handleBookmark"
                  />
                </span>
              </a-tooltip>
              <a-tooltip title="Playback trace video in new tab">
                <a-button
                  size="small"
                  type="primary"
                  @click="goToTraceTab(video)"
                >
                  <template #icon>
                    <i class="bi bi-box-arrow-up-right" />
                  </template>
                </a-button>
              </a-tooltip>
            </a-space>
            <!-- </div> -->
          </a-descriptions-item>
          <a-descriptions-item
            label="Cycle Time"
            class="d-flex align-items-center"
            span="3"
            :label-style="{ width: '6em' }"
          >
            <a-tag color="blue">
              {{ video?.cycle_time }}
            </a-tag>
          </a-descriptions-item>

          <a-descriptions-item
            label="Missed Steps"
            class="d-flex align-items-center"
            span="3"
            :label-style="{ width: '7em' }"
          >
            <a-tag color="blue">
              {{ video?.missedStepsName?.length || 0 }}
            </a-tag>
          </a-descriptions-item>
        </a-descriptions>

        <div
          :id="isInterestingCycles ? 'area1-' + index : 'area2-' + index"
          class="d-flex align-items-center"
        >
          <!-- /**
             * Renders a set of buttons for a support user to interact with a video cycle.
             * The buttons allow the user to:
             * - Make the current cycle 'good' or 'bad'
             * - Archive the current cycle
             *
             * The buttons are only displayed if the current user has the 'support_user' role.
             */ -->
          <a-space class="ml-auto" size="middle">
            <a-dropdown
              trigger="click"
              placement="bottom"
              :get-popup-container="getPopupContainer"
            >
              <a-button size="small" style="margin-top: 2px">
                Show Missed Steps
                <!-- <template #icon>
                  <DownOutlined />
                </template> -->
              </a-button>

              <template #overlay>
                <a-menu>
                  <a-menu-item v-for="ms in video?.missedStepsName" :key="ms">
                    {{ ms }}
                  </a-menu-item>
                  <a-menu-item v-if="video?.missedStepsName?.length === 0">
                    No missed steps
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
            <a-tooltip title="Show comments">
              <a-badge
                :count="video.no_of_comments"
                :overflow-count="10"
                color="#1890ff"
              >
                <a-button
                  size="small"
                  @click="$emit('showComments', true, index)"
                >
                  <!-- <div class="d-flex justify-content-between"> -->

                  <template #icon>
                    <CommentOutlined />
                  </template>
                  <!-- <span>Comments</span>

                <a-tag color="processing" class="ml-3">
                  {{ video.no_of_comments }}
                </a-tag> -->
                  <!-- </div> -->
                </a-button>
              </a-badge>
            </a-tooltip>

            <a-tooltip title="Show tags">
              <a-badge
                :count="video.tag_counts"
                :overflow-count="10"
                color="#1890ff"
              >
                <a-button size="small" @click="openTagModal">
                  <template #icon>
                    <TagsOutlined />
                  </template>
                </a-button>
              </a-badge>
            </a-tooltip>

            <a-popconfirm
              :title="`Are you sure you want to mark this as a ${
                video?.is_good_cycle === 0 ? 'Good' : 'Bad'
              } cycle?`"
              ok-text="Yes"
              cancel-text="No"
              @confirm="toggleCycleStatus()"
            >
              <a-tooltip
                :title="`Mark as ${
                  video?.is_good_cycle === 0 ? 'Good' : 'Bad'
                }`"
              >
                <a-button size="small" @click="">
                  <template #icon>
                    <ToolOutlined />
                  </template>
                </a-button>
                <!-- <a-button @click="openTagModal">
                  <div class="d-flex justify-content-between">
                    <span>Tags</span>

                    <a-tag color="processing" class="ml-3">
                      {{ video.tag_count }}
                    </a-tag>
                  </div>
                </a-button> -->
              </a-tooltip>
            </a-popconfirm>

            <a-tooltip v-if="role == roles.support_user" title="Archive cycle">
              <a-popconfirm
                title="Are you sure you want to archive this cycle?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="archiveCycle(video)"
              >
                <a-button size="small" type="danger">
                  <template #icon>
                    <DeleteOutlined />
                  </template>
                </a-button>
              </a-popconfirm>
            </a-tooltip>
          </a-space>
        </div>
      </div>
      <!-- </a-row> -->
    </a-card>
  </a-col>
  <a-modal
    id="tags-modal"
    v-model:visible="tagsModal"
    title="Tags"
    :footer="null"
    centered
  >
    <template #closeIcon>
      <close-outlined id="tags-modal-close-btn" />
    </template>
    <div style="height: 30vh">
      <a-select
        v-model:value="currentSelectedTag"
        id="select-tag-input"
        show-search
        mode="multiple"
        placeholder="Select Tag"
        :disabled="loadingTags"
        :loading="loadingTags"
        :options="tagOptions"
        :filter-option="filterOption"
        class="w-100"
        dropdown-class-name="select-tag-input-class"
        @select="handleAddTag"
        @deselect="handleTagRemove"
      />
    </div>
  </a-modal>
</template>
<script>
import {
  CommentOutlined,
  DeleteOutlined,
  ToolOutlined,
} from '@ant-design/icons-vue';
import NotFoundImage from 'src/components/shared/Components/NotFound.vue';
import { roles } from 'src/config/roles-config';
import ThumbnailService from 'src/services/thumbnails';
import { getThumbnailUrl, traceThumbnail } from 'src/utils/thumbnail.js';
import { mapGetters } from 'vuex';
import TelemetryService from '../../../../services/telemetry';
import VideoService from '../../../../services/videos';
import httpClient from 'src/service/httpClient.js';
import { CloseOutlined, TagsOutlined } from '@ant-design/icons-vue';

export default {
  components: {
    ToolOutlined,
    DeleteOutlined,
    TagsOutlined,
    CommentOutlined,
    NotFoundImage,
    CloseOutlined,
  },
  props: ['video', 'cycle', 'index', 'isInterestingCycles', 'goToTraceTab'],
  emits: ['updateVideoUrl', 'handlePlay', 'showComments', 'updateVideo'],
  data: function () {
    return {
      playable: false,
      document: document,
      queuedthumbnailGenerations: [],
      isThumbnailExist: true,
      thumbnailImage: null,
      roles,
      tagsModal: false,
      isLoadingTag: false,
      currentSelectedTag: [],
      orgTags: [],
      loadingTags: false,
      selectedVideo: null,
      tags: [],
    };
  },
  setup() {
    const userId = localStorage.getItem('id');
    const userName = localStorage.getItem('name');
    const organization = localStorage.getItem('organization');
    return { userId, userName, organization };
  },
  computed: {
    ...mapGetters(['selectedTask', 'organization', 'role']),
    getCycleTagColor() {
      return this.video.is_good_cycle === 0 ? 'error' : 'success';
    },

    getCycleTagText() {
      return this.video.is_good_cycle === 0 ? 'Bad' : 'Good';
    },
  },
  mounted() {
    this.thumbnailImage = this.thumbnailUrl(this.video);
  },
  watch: {
    video: {
      handler: function (value) {
        this.thumbnailImage = this.thumbnailUrl(value);
        if (
          !value.isThumbnailExist &&
          !this.isThumbnailGenerationInitiated(value)
        ) {
          this.queuedthumbnailGenerations.push(value.id);
          this.handleThumbnailGeneration(value);
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.thumbnailImage = this.thumbnailUrl(this.video);
  },

  methods: {
    // need to call this from here.

    async toggleCycleStatus() {
      try {
        /**
         * Reverts the cycle status for the current video.
         *
         * This method sends a request to the TelemetryService to update the `is_good_cycle` property
         * for the current video's cycle. After the update, it emits an event to refresh the analytics
         * and trace data.
         */
        let payload = {
          is_good_cycle: !this.video.is_good_cycle,
        };
        await TelemetryService.revertCycle(this.video.cycle_id, payload);
        this.$emit('handleCycleCount', payload.is_good_cycle);
        this.emitter.emit('getAnalyticsAndTrace', true);
      } catch (error) {
        console.error('Error reverting cycle', error);
      }
    },

    handleBookmark() {
      this.$emit('updateVideo', {
        videoName: this.video.fileName,
        updatedObj: { ...this.video, starred: !this.video.starred },
      });
      VideoService.updateTaskRecord(this.video.id, {
        starred: !this.video.starred,
      });
    },

    isThumbnailGenerationInitiated(video) {
      return this.queuedthumbnailGenerations.includes(video.id);
    },

    async handleThumbnailGeneration(video) {
      const [error] = await this.generateThumbnail(video);
      if (error) {
        return;
      }
      this.$emit('updateVideo', {
        videoName: this.video.fileName,
        updatedObj: { ...this.video, isThumbnailExist: true },
      });
      this.queuedthumbnailGenerations = this.queuedthumbnailGenerations.filter(
        (id) => id !== video.id
      );
    },

    async generateThumbnail(record) {
      const payload = {
        id: record.id,
      };
      return await ThumbnailService.generateThumbnail(payload);
    },

    handleError(video) {
      this.$emit('updateVideo', {
        videoName: video.fileName,
        updatedObj: { ...video, isThumbnailExist: false },
      });
    },

    thumbnailUrl(record) {
      const taskId = this.selectedTask;
      if (!taskId || !this.video || !Object.keys(this.video).length) return;
      const thumbnailPath = traceThumbnail(record, taskId, this.organization);
      return getThumbnailUrl(thumbnailPath);
    },

    getObjectLength() {
      return Object.keys(this.video).length;
    },

    async handleVideoPlay() {
      this.$emit('handlePlay', {
        video: this.video,
        index: this.index,
      });
    },

    updateVideoUrl(videoName, url) {
      this.$emit('updateVideoUrl', videoName, url, this.index);
    },

    getFileName(filename) {
      let name = filename?.slice(0, 30);
      if (filename?.length > 30) name += '...';
      return name;
    },

    getPopupContainer() {
      const elemId = this.isInterestingCycles
        ? 'area1-' + this.index
        : 'area2-' + this.index;
      return document.getElementById(elemId);
    },

    async archiveCycle({ cycle_id }) {
      await TelemetryService.archiveCycle(cycle_id);
      this.emitter.emit('getAnalyticsAndTrace', true);
    },

    openTagModal() {
      this.fetchTags(this.video);
    },

    async fetchOrgTags() {
      this.isLoadingTag = true;
      const res = await httpClient.get('organization/task_record/tags', false);
      this.orgTags = res;
      this.isLoadingTag = false;
    },

    async handleAddTag(tagId) {
      this.loadingTags = true;
      if (!this.selectedVideo.entity_id) {
        const res = await this.createEntity();
        const temp = { ...this.selectedVideo };
        temp['entity_id'] = res.id;
        this.selectedVideo = temp;
        this.updateTaskRecord(
          { entity_id: this.selectedVideo.entity_id },
          this.selectedVideo,
          false
        );
      }

      const tag_res = await this.createTag(tagId);
      tag_res['tag_name'] = this.orgTags.find(
        (ot) => ot.id === tag_res.tag_name
      );

      this.tags = [...this.tags, tag_res];
      this.loadingTags = false;
    },

    async handleTagRemove(tagId) {
      this.loadingTags = true;
      const { entity_id } = this.selectedVideo;
      const entityToDelete = this.tags.find(
        (t) => t.entity_id === entity_id && t.tag_name.id === tagId
      );
      if (!entityToDelete) return;

      const res = await httpClient.delete(
        `organization/entity/tags/`,
        entityToDelete.id,
        false,
        false,
        false
      );
      if (res === 'error') {
        console.log('error occured while removing tag');
      }

      this.loadingTags = false;
    },

    showTagsModal(data) {
      this.selectedVideo = data.taskRecord;
      this.orgTags = data.orgTags;
      this.tags = data.entityTag;
      this.currentSelectedTag = data.entityTag.map((e) => e.tag_name.id);
      this.tagOptions = data.orgTags.map((el) => ({
        value: el.id,
        label: el.tag_name,
      }));
      this.tagsModal = true;
    },

    async createEntity() {
      const payload = {
        name: this.selectedVideo.fileName,
        organization: this.organization,
      };
      return await VideoService.createEntityForVideo(payload);
    },

    async fetchTags(record) {
      //predefined tags available for particular organization
      const orgTag_response = await httpClient.get(
        `organization/task_record/tags?organization_id=${this.organization}`
      );

      if (!record.entity_id) {
        this.showTagsModal({
          taskRecord: record,
          orgTags: orgTag_response,
          entityTag: [],
        });
      } else {
        const entityTag_response = await httpClient.get(
          `organization/entity/tags?entity_id=${record.entity_id}`
        );
        this.showTagsModal({
          taskRecord: record,
          orgTags: orgTag_response,
          entityTag: entityTag_response,
        });
      }
    },

    async createTag(tagId) {
      const payload = {
        tag_name: tagId,
        entity_id: this.selectedVideo.entity_id,
        user_id: parseInt(this.userId),
      };

      return await httpClient.post(
        `organization/entity/tags`,
        payload,
        false,
        false,
        false
      );
    },

    async updateTaskRecord(updatedData, videoToUpdate, alert = true) {
      this.updateVideo(videoToUpdate.fileName, updatedData);
      const [error] = await VideoService.updateTaskRecord(
        videoToUpdate.id,
        updatedData
      );
      if (error && alert) {
        this.toast.success('Error occured while updating the record!');
      }
      if (alert) this.toast.success('Record updated successfully!');
    },

    updateVideo(videoName, updatedObj) {
      if (this.video.fileName === videoName) {
        for (const key in updatedObj) {
          if (updatedObj.hasOwnProperty(key)) {
            this.video[key] = updatedObj[key];
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.video-img {
  /* width: 60%;
  height: 100%; */
  width: 120px;
  height: 120px;
  border-radius: 5px;
}

.rating {
  width: 100%;
  margin-top: 12px;
  justify-content: center;
}
/* 
@media only screen and (max-width: 991px) {
  .video-img {
    height: 240px;
    margin-bottom: 12px;
  }
} */
</style>
